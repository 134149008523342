/* You can add global styles to this file, and also import other style files */
html, body
  font-size: 12px

@import "~@fortawesome/fontawesome-pro/scss/solid.scss"
@import "~@fortawesome/fontawesome-pro/scss/light.scss"
@import "~@fortawesome/fontawesome-pro/scss/regular.scss"
@import "~@fortawesome/fontawesome-pro/scss/fontawesome.scss"
@import "~bootswatch/dist/united/variables.scss"
@import "~bootstrap/scss/bootstrap.scss"
@import "~bootswatch/dist/united/_bootswatch.scss"
@import '~quill/dist/quill.core.css'
@import '~quill/dist/quill.bubble.css'
@import '~quill/dist/quill.snow.css'

// Global styles
@import ../../lvadg/sass/common
@import sass/fixes
@import sass/modellist
