h1 small
  margin-left: 1rem
h1.page-header
  @media (max-width: 800px)
    font-size: 2rem
    small
      display: block
      font-size: 1rem
      line-height: 1.1rem
      padding-left: 40px
      margin-left: 0
    bsac-issue
      display: none
.fa-s
  margin-right: 0.4em
.tab-content
  padding-top: 0.4rem
  padding-left: 0.2rem
  padding-right: 0.2rem
a
  color: black
  text-decoration: none
a:hover
  color: darkblue
.card-header
  font-weight: bold
  font-size: 1.2rem
.pointer
  cursor: pointer
pre.todo
  padding: 10px
  margin: 10px
  border: 1px solid red
  background-color: lightyellow
  color: darkred
.filteritem
  margin-top: 3px
bsac-issue
  font-size: 0.9rem
  a
    color: darkorange
  a:hover
    color: red
.badge-spaced
  margin-right: 3px
  margin-left: 3px
  padding: 5px
.bg-dev
  background-color: darkslategrey
.valid-feedback
  color: green
.is-valid
  color: green
  border-color: darkgreen
.is-invalid
  color: red
  border-color: darkred

bsac-dd-field[type="textarea"] dd form::after,  bsac-dd-field[type="auto"] dd form.bsac-form-textarea::after
  content: "Ctrl+click pour valider"
  font-size: 0.8rem
  color: grey

.tascroll + .dropdown-menu
  max-height: 400px
  overflow-y: auto


//- BS5 transition
.btn-block
  width: 100% !important
.form-group
  margin-bottom: 0.45rem
