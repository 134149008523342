.modellist-vertical
  .filtersetitem
    border-top: 1px solid lightgrey
    padding-top: 10px
    padding-bottom: 10px

  .list-select-filters
    border-top: 1px solid grey
    padding-top: 10px
    padding-bottom: 10px
.modellist-horizontal
  bsac-filter-set
    @include make-row()
    background-color: lightgrey
    padding-top: 4px
    padding-bottom: 4px
    margin-bottom: 3px

  .filter-management
    background-color: lightgrey
    padding-top: 4px
    padding-bottom: 4px
    margin-bottom: 3px
    bsac-model-list-persistence-save, bsac-model-list-persistence-load
      .btn-link
        color: black

  .filtersetitem
    @include make-col-ready()
    @include make-col(4)

table.distances
  width: 100%
  td
    padding: 0
    border: 0
  td.dist
    text-align: right
  tr:hover
    background-color: #76C8C8
  div.via
    font-size: 0.7rem
    line-height: 0.5rem
    margin-bottom: 0.5rem
